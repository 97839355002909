@font-face {
  font-family: 'icomoon';
  src:  url(/assets/icomoon.c738911b1ecc11def3df8324b1f14f51.eot);
  src:  url(/assets/icomoon.c738911b1ecc11def3df8324b1f14f51.eot#iefix) format('embedded-opentype'),
    url(/assets/icomoon.68e36099601660bf121e6d4bd07d8506.ttf) format('truetype'),
    url(/assets/icomoon.04aa333fe1352dab281a9750da9ce8d9.woff) format('woff'),
    url(/assets/icomoon.453f68d2edef526b8bad9749559f171f.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aico-"], [class*=" aico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aico-check:before {
  content: "\e90b";
}
.aico-userNormal:before {
  content: "\e92b";
}
.aico-reading:before {
  content: "\e901";
}
.aico-saccades:before {
  content: "\e902";
}
.aico-smooth_pursuit:before {
  content: "\e903";
}
.aico-strabizmus:before {
  content: "\e904";
}
.aico-convergence:before {
  content: "\e90a";
}
.aico-visual_field_evaluation:before {
  content: "\e905";
}
.aico-worth_four_dot_test:before {
  content: "\e906";
}
.aico-cover_test:before {
  content: "\e907";
}
.aico-hess:before {
  content: "\e908";
}
.aico-nystagmus_evaluation:before {
  content: "\e909";
}
.aico-ptosis_evaluation:before {
  content: "\e910";
}
.aico-pupillary_evaluation:before {
  content: "\e911";
}
.aico-acuity:before {
  content: "\e912";
}
.aico-amsler_grid_test:before {
  content: "\e913";
}
.aico-color_vision_test:before {
  content: "\e914";
}
.aico-unseen:before {
  content: "\e915";
}
.aico-zoomIn:before {
  content: "\e916";
}
.aico-zoomOut:before {
  content: "\e917";
}
.aico-triangleDown:before {
  content: "\e918";
}
.aico-triangleUp:before {
  content: "\e919";
}
.aico-refresh:before {
  content: "\e91a";
}
.aico-search:before {
  content: "\e91b";
}
.aico-seen:before {
  content: "\e91c";
}
.aico-checked:before {
  content: "\e91d";
}
.aico-notAplicable:before {
  content: "\e91e";
}
.aico-noUpload:before {
  content: "\e91f";
}
.aico-play:before {
  content: "\e920";
}
.aico-pause:before {
  content: "\e900";
}
.aico-diagram:before {
  content: "\e921";
}
.aico-close:before {
  content: "\e922";
}
.aico-help:before {
  content: "\e923";
}
.aico-connections:before {
  content: "\e924";
}
.aico-calendar:before {
  content: "\e925";
}
.aico-chat:before {
  content: "\e926";
}
.aico-arrowEndLeft:before {
  content: "\e927";
}
.aico-arrowEndRight:before {
  content: "\e928";
}
.aico-arrowLeft:before {
  content: "\e929";
}
.aico-arrowRight:before {
  content: "\e92a";
}
